/* 
 * Custom Jquery for this theme
 */

jQuery(function($){

  setTimeout(function() {
    jQuery('.sticky-btn').closest('body').addClass("sticky-added");
  },1500);

  // setTimeout(function() {
  //   jQuery('.scan-content').addClass("scan-hidden");
  // },3500);
  // jQuery('body').on('click','.scan-content .msg-close',function() {
  //   jQuery('.scan-content').fadeOut();
  // });
  

	/* For iOS touch hover effect */
	document.addEventListener("touchstart", function() {},false);

	jQuery('body').on('click','.expand-btn',function() {
    jQuery(this).parent().toggleClass("expand-open");
  });

  jQuery('body').on('click','.msg-close',function() {
    jQuery('.popup-msg').fadeOut();
  });

  jQuery('body').on('click','.slot-session h6',function() {
    jQuery(this).next().slideToggle();
    jQuery(this).parent().toggleClass("session-closed");
  });

  // setTimeout(function() {
  //   jQuery('<label>Date of Birth <span class="required">*</span></label>').appendTo('.dob-field .masked-calendar datetime-mask');
  // },500);

  

});

jQuery(window).on('load resize', function () {
   
});